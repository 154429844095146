<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <div class="card text-left">
        <div class="card-header">
            <h5>Contact me</h5>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-white-50">E-mail</h6>
                <p class="card-text">
                    <span class="greyFont"></span>contact@hoz-it.com <br />
                </p>
            </li>
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-white-50">Social networks</h6>
                <p class="card-text">
                    <a href="https://www.linkedin.com/in/carlos-de-la-hoz-32b9a081/"><i class="fa fa-linkedin-square my-social-icons fa-lg my-icon-margin my-link-icon" aria-hidden="true"></i></a>Linkedin <br />
                </p>
                <p class="card-text">
                    <a href="https://www.xing.com/profile/Carlos_delaHozFernandez"><i class="fa fa-xing-square my-social-icons fa-lg my-icon-margin my-link-icon" aria-hidden="true"></i></a>Xing<br />
                </p>
                <p class="card-text">
                    <a href="https://stackexchange.com/users/6099691/carloshf"><i class="fa fa-stack-overflow my-social-icons fa-lg my-icon-margin my-link-icon" aria-hidden="true"></i></a>StackOverflow<br />
                </p>
            </li>
        </ul>
    </div>
</div>