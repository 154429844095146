<header class="masthead mb-auto">
    <div class="inner">
        <a style="float:left" [routerLink]="['home']">
            <h3 class="masthead-brand ">hoz-it</h3>
        </a>
        <nav class="nav nav-masthead justify-content-center float-right">
            <a class="nav-link" [routerLink]="['home']" routerLinkActive="active">Home</a>
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                About me
              </a>
            <div class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                <a class="nav-link" [routerLink]="['profile']" routerLinkActive="active">Profile</a>
                <a class="nav-link" [routerLink]="['projects']" routerLinkActive="active">Projects</a>
            </div>
            <a class="nav-link" [routerLink]="['contact']" routerLinkActive="active">Contact</a>
            <a class="nav-link" [routerLink]="['imprint']" routerLinkActive="active">Imprint</a>
            <div class="form-inline my-2 my-lg-0">
                <input class="form-control mr-sm-2" type="text" placeholder="Search in projects" (keyup.enter)="navSearch(navSearchText.value)" #navSearchText>
                <!-- <button (click)="navSearch(navSearchText.value)" class="btn btn-outline-primary my-2 my-sm-0" type="button">Search</button> -->
            </div>

        </nav>
    </div>
</header>