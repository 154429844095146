<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <div class="card text-left projects-container">
        <div class="card-body project-iteration" *ngFor="let project of projects let i = index">
            <h5 class="card-title">{{project.name}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{project.customer}}</h6>
            <p class="card-text text projects-text">{{project.description}}</p>
            <button class="btn btn-light" type="button" data-toggle="collapse" [attr.data-target]="'#collapse-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-' + i" (click)="moreInfoClick($event)">
                {{more}}
            </button>
            <br>
            <div class="collapse" [attr.id]="'collapse-' + i">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <h6 class="card-subtitle mb-2 text-muted">When</h6>
                        <p class="card-text text projects-text ng">{{project.dateInfo}}</p>
                    </li>
                    <li class="list-group-item">
                        <h6 class="card-subtitle mb-2 text-muted">Contributions</h6>
                        <p class="card-text text projects-text" *ngFor="let contribution of project.contributions">{{contribution}}</p>
                    </li>
                    <li class="list-group-item">
                        <h6 class="card-subtitle mb-2 text-muted">Technologies</h6>
                        <p>
                            <span class="badge badge-secondary" *ngFor="let technology of project.technologies">{{technology}}</span>

                        </p>
                    </li>
                    <li class="list-group-item">
                        <h6 class="card-subtitle mb-2 text-muted">Functionalities</h6>
                        <p class="card-text text projects-text" *ngFor="let functionality of project.functionalities">{{functionality}}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="card-body">
            <h5 class="card-title">More to come...</h5>

        </div>
    </div>
    <!-- </div> -->