<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <main role="main" class="inner cover">
        <h1 class="display-4">Fullstack developer with {{yearsExp}} years of experience</h1>
        <h2 class="display-6">Personal skills</h2>
        <p class="lead"> <span class="greyFont">Experienced worker:</span> I am confident with my work and do not hesitate of what I do.</p>
        <hr>
        <p class="lead"> <span class="greyFont">People skills:</span> I feel comfortable working with teammates and customers. I always try to find solutions to problems that fit everyone as much as possible.</p>
        <hr>
        <p class="lead"> <span class="greyFont">Empathy:</span> Understanding other people's wishes and feelings is no problem for me. Because of this I can always meet the client's requirements or easily work with complicated colleagues.</p>
        <hr>
        <p class="lead"> <span class="greyFont">Problem solving:</span> My work experience gave me the skills needed to know how to solve any kind of obstacle within a project. Making some phone calls or researching for a solution is part of everyday life.</p>
        <hr>
        <h2 class="display-6">Technical skills</h2>
        <p class="lead"> <span class="greyFont">Front-end frameworks:</span> Angular, JSP, Struts2</p>
        <hr>
        <p class="lead"> <span class="greyFont">Back-end frameworks:</span> Spring Boot, Spring MVC, JavaEE</p>
        <hr>
        <p class="lead"> <span class="greyFont">Servers:</span> Node.js, Tomcat 7-9, JBOSS, Websphere</p>
        <hr>
        <p class="lead"> <span class="greyFont">Database:</span> Oracle 10g-12c, Hibernate (mapping)</p>
        <hr>
        <p class="lead"> <span class="greyFont">Others:</span> Java, Typescript, Android, C#, Subversion, GIT, Bitbucket, RestServices, Jira, HTML5, CSS3, JavaScript, ResponsiveDesign, SpringSecurity, Selenium, Richfaces, Primefaces</p>
        <hr>
        <p class="lead"> <span class="greyFont">Not what you are looking for?</span> This is a summary of the main technologies I use. Along my carreer there are many others so if you require one not included here, it is likely that I know a similar one or I am familiar
            with it.</p>
    </main>
</div>