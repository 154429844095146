<!-- <div class="container" style="margin-top:20px;">
    <div class="row">
        <div class="col-8">
            <h1 class="display-4">Welcome!</h1>
            <p class="lead">Hello, I am Carlos de la Hoz.</p>
            <p class="lead">I am a fullstack software developer mainly specialized in Java and Angular.</p>
        </div>
        <div class="col-4"><img src="./assets/img/profilePic.png" alt="this is me" style="width: 200px;"></div>
    </div>
</div> -->

<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <main role="main" class="inner cover">
        <h1 class="display-4 animated fadeIn fast">Hello, I am Carlos.</h1>
        <p class="lead animated fadeIn">I am a
            <B>FREELANCE</B> fullstack software developer mainly specialized in Java and Angular. Currently based in Stuttgart (Germany). If you have any questions do not hesitate to contact me.</p>
        <div class="mx-auto animated fadeIn"><img src="./assets/img/profilePic.png" alt="this is me" style="width: 200px;"></div>
    </main>
</div>