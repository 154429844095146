import { Injectable } from '@angular/core';
import { Project } from '../entities/project';


@Injectable()
export class ProjectService {


    private readonly homePage:Project = new Project(
        "My home page", 
        "hoz-it",
        "My home page where you can see all information related to my career as a software developer", 
        new Date("2020-03-01"),
        null, 
        ["Creation of a new webpage from scratch using the newest technologies.", "Deployment of the app on public domain."],
        ["Angular9", "Bootstrap4", "Git", "HTML5", "CSS", "SCSS", "JavaScript", "NodeJS", "TypeScript"],
        ["View designed with bootsrap from scratch","Information loaded through servers within the application (server connection to back-end to come)", "Use of newest elements like navigation bars or search functions."],
    );

    private readonly gam:Project = new Project(
        "Expats management tool", 
        "Bosch",
        "A complex application to calculate salaries and remunerations for all expatriates worldwide.", 
        new Date("2016-06-01"),
        null, 
        ["Implementation of new complex functionalities and optimisation of existing ones","Migration of frameworks (Oracle12c, Tomcat9, Struts 2.5...)."],
        ["Java8", "Tomcat9", "Subversion", "Struts2", "Jira", "Hibernate", "Oracle12c", "Spring MVC", "JavaSpring Security", "JSP", "HTML", "JavaScript", "CSS" ],
        ["Expatriates administration with complex search.","Calculation of remunerations, bonus and other allowances through connection to external services.", "User administration with specific focus on roles."]
    );

    private readonly profiles:Project = new Project(
        "Employees profile manager", 
        "Key Software & Systeme",
        "As a consultancy company, Key needed an app to easily check the profiles of the employees in order to apply to new projects.", 
        new Date("2016-01-01"),
        new Date("2017-06-01"), 
        ["Design of architecture","Development from scratch from back and front-end", "Creation of web services and reports"],
        ["Angular", "JavaScript", "HTML5", "CSS3", "Bootstrap", "Oracle", "Restful services", "JavaEE 6", "Java8", "SpringBoot", "Spring Security", "Hibernate", "Git", "Jira", "Bitbucket" ],
        ["Add, remove and edit employees","Manage skills and projects", "Advanced search", "Creation of reports"]
    );

    private readonly scania:Project = new Project(
        "Responsive adaptation of web ERM application", 
        "Scania Deutschland",
        "Adaptation of a fully working application to be responsive so the employees could use it on tablets", 
        new Date("2016-04-01"),
        new Date("2016-05-15"), 
        ["Adapt the given layouts to the new responsive model","Creation of single responsive elements (icons, menus...)", "Optimization of performance of specific elements (css loading)"],
        ["CSS3", "HTML5", "Primefaces", "JSP", "ResponsiveDesign", "Subversion", "Tomcat"],
        ["Management of mechanical workshop","Organisation of truck expeditions", "Management of employees", "Management of sellings and providers"]
    );

    private readonly bfisMobile:Project = new Project(
        "Adaptation to Android from Web Application", 
        "Bosch",
        "Adaptation of web application to manage wireless frequences connections for different gadgets", 
        new Date("2015-03-12"),
        new Date("2015-06-31"), 
        ["Creation of Rest services on the back-end","Development of Front-end on android app"],
        ["Android", "JavaEE", "Oracle", "Restful Services", "JSON", "JBOSS", "Hibernate"],
        ["Secured login through services","Android views do display different functions"]
    );

    private readonly keyTimeOnline:Project = new Project(
        "HRMS for Employee data management", 
        "Key Software & Systeme",
        "Development and support of intern application to manage employees and projects", 
        new Date("2015-03-12"),
        new Date("2017-06-31"), 
        ["Automated mailing tasks", "Improvement of security", "Improvement of usability", "Creation of new reports"],
        ["JavaEE", "Java", "CSS", "HTML", "JavaScript", "Oracle", "JBOSS", "Subversion", "JPA", "JSP", "RichFaces", "SpringMVC"],
        ["Secured login through encriptation","Mailing tasks that previously where hand made", "Timesheets for employees", "Management of time schedule for projects"]
    );

    private projects:Project[] = [this.homePage, this.gam, this.profiles, this.scania, this.bfisMobile, this.keyTimeOnline ];

    private projectsSearch:Project[] = [];

    constructor() {
     }

     getProjects(){
         return this.projects;
     }

     searchProjects( searchText:string ){
         this.projectsSearch = [];
        for( let project of this.projects ){
            let projectAux = Object.assign({}, project);
            this.projectsSearch.push(projectAux);
        }
        // searchText = searchText.toLowerCase();
        for( let project of this.projectsSearch ){
            this.seachInProject(project, searchText);
        }
        return this.projectsSearch;
     }

     private seachInProject( project:Project, searchText:string ){
        project.name = this.searchInString(searchText, project.name);
        project.customer = this.searchInString(searchText, project.customer);
        project.description = this.searchInString(searchText, project.description);
        project.dateInfo = this.searchInString(searchText, project.dateInfo);
        project.contributions = this.searchInList(searchText, project.contributions);
        project.technologies = this.searchInList(searchText, project.technologies);
        project.functionalities = this.searchInList(searchText, project.functionalities);
     }

     private searchInString( searchText:string, myString:string ){
        if(searchText === "")
            return myString;
        searchText = searchText.toLowerCase();
        var regex = new RegExp( searchText, "g" );

        var cap = searchText.toUpperCase();
        var regexCap = new RegExp( cap, "g" );

        var firstCap = this.capitalizeFirstLetter(searchText);
        var regexCapFirst = new RegExp( firstCap, "g" );

        myString = myString.replace(regex, '<span class="highlightedSearch">' + searchText + '</span>');
        myString = myString.replace(regexCap, '<span class="highlightedSearch">' + cap + '</span>');
        myString = myString.replace(regexCapFirst, '<span class="highlightedSearch">' + firstCap + '</span>');
        
        return myString;
     }

     private searchInList( searchText:string, myStrings:string[] ){
         let i = 0;
         let newList:string[] = [];
        for( let myString of myStrings ){
            newList.push(this.searchInString(searchText, myString));
        }
        return newList;
     }

     private capitalizeFirstLetter(myString:string) {
        var result = myString.charAt(0).toUpperCase();
        result += myString.slice(1);
        
        return result;
      }
    
}