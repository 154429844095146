import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Project } from '../../entities/project';
import { ProjectService } from '../../services/projects.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  projects:Project[] = [];
  searchText:string;
  more:string = "more..."
  less:string = "less..."

  constructor( private activatedRoute:ActivatedRoute, private router:Router, private _projectService:ProjectService ) { }

  ngOnInit(): void {
    // this.projects = this._projectService.getProjects();

    this.activatedRoute.params.subscribe ( params =>{
      this.searchText =params['searchText'];
      this.projects = this._projectService.searchProjects( params['searchText']);
      console.log( this.projects );
    })

  }

  moreInfoClick(event) {
    if(event.toElement.textContent === this.less)
      event.toElement.textContent = this.more;
    else
      event.toElement.textContent = "less...";
  }

}
