import { Util } from '../util/Util';

export class Project {
    name:string;
    customer:string;
    description:string;
    dateInfo:string;
    contributions:string[];
    technologies:string[];
    functionalities:string[];

    constructor(name?:string,
        customer?:string,
        description?:string,
        startDate?:Date,
        endDate?:Date,
        contributions?:string[],
        technologies?:string[],
        functionalities?:string[]) {
            this.name = name;
            this.customer = customer;
            this.description = description;
            const startMonth = Util.getMonths()[startDate.getMonth()];
            if(endDate != null) {
                const endMonth = Util.getMonths()[endDate.getMonth()];
                this.dateInfo = startMonth + " " + startDate.getFullYear() + " - " + endMonth + " " + endDate.getFullYear();
            }   
            else{
                this.dateInfo = startMonth + " " + startDate.getFullYear() + " - " + "today";
            }
            this.contributions = contributions;
            this.technologies = technologies;
            this.functionalities = functionalities;
        }
    


}