<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <div class="card text-left">
        <div class="card-header">
            <h5>Legal Information</h5>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-white-50">Contact</h6>
                <p class="card-text">
                    Carlos de la Hoz <br />
                    <span class="greyFont">Mail </span>contact@hoz-it.com <br />
                </p>
            </li>
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-white-50">Legal notice</h6>
                <p class="card-text">
                    The contents of these pages were prepared with utmost care. Nonetheless, I cannot assume liability for the timeless accuracy and completeness of the information.
                    <br><br> This website contains links to external websites. As the contents of these third-party websites are beyond my control, we cannot accept liability for them. Responsibility for the contents of the linked pages is always held
                    by the provider or operator of the pages. </p>
            </li>
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-white-50">About hoz-it</h6>
                <p class="card-text">
                    <span class="greyFont">hoz-it</span> is my commercial brand (not registered) to reference me as a software developer. </p>
            </li>
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-white-50">Data protection</h6>
                <p class="card-text">
                    In general, when visiting the website of <span class="greyFont">www.hoz-it.com</span>, no personal data are saved. However, these data can be given on a voluntary basis. No data will be passed on to third parties without your consent.
                    We point out that in regard to unsecured data transmission in the internet (e.g. via email), security cannot be guaranteed. Such data could possibIy be accessed by third parties. </p>
            </li>
        </ul>
    </div>
    <hr>
    <div class="card text-left">
        <div class="card-header">
            <h5>Special thanks to the community</h5>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-muted">Icons on home page by order of appearance</h6>
                <div>
                    <p>Cover template for <a class="my-a" href="https://getbootstrap.com/">Bootstrap</a>, by <a class="my-a" href="https://twitter.com/mdo">@mdo</a>.</p>
                </div>
            </li>
            <li class="list-group-item">
                <h6 class="card-subtitle mb-2 text-muted">More thanks to come!</h6>
            </li>
        </ul>
    </div>
</div>