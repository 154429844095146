import { Component, OnInit } from '@angular/core';
import { Project } from '../../entities/project';
import { ProjectService } from '../../services/projects.service';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  projects:Project[] = [];

  more:string = "more..."
  less:string = "less..."

  constructor(private _projectService:ProjectService) { 
    
   }

  ngOnInit(): void {
    this.projects = this._projectService.getProjects();
  }

  moreInfoClick(event) {
    if(event.toElement.textContent === this.less)
      event.toElement.textContent = this.more;
    else
      event.toElement.textContent = "less...";
  }

}
